<template>
    <div class="root">
        <BorderTitle :title="$t('application.downloadServiceTitle')" />
        <div class="downloadTips">
            <span>{{ $t('application.downloadTips', { downloadCountRemain }) }}</span>
            <div v-loading="downloadButtonLoading" @click="downloadRandom" class="downloadButton">
                <span>{{ $t("application.downloadButtonText") }}</span>
                <img src="../assets/images/icon_download_active.png" class="downloadIcon">
            </div>
        </div>

        <!-- history-list -->
        <div class="historyListContainer">
            <!-- <div v-if="downloadHistoryList.length" class="downloadHistoryTitle">
                <clock />
                <span>{{ $t("application.historyListTitle") }}</span>
            </div> -->
            <div v-if="downloadHistoryList.length" class="historyList">
                <div v-for="(item, index) in downloadHistoryList" :key="index" class="historyItem">
                    <span class="createTime">{{ $t('application.randomGenerateTime') }} : {{ item.addtime }}</span>
                    <div @click="downloadCurrentFile(item.url)" class="inlineButton">
                        <span style="margin-left: 0;">{{ $t("application.downloadButtonText") }}</span>
                    </div>
                </div>
            </div>
            <div v-else class="emptyText">{{ $t("application.noHistoryTips") }}</div>
        </div>
        <div class="paginationContainer">
            <el-pagination background :page-size="pagination.limit" :total="totalCount"
                @current-change="handlePageChange"></el-pagination>
        </div>
    </div>
</template>

<script>
// SVG
import copy from "../components/svg/copy.vue";
import download from "../components/svg/download.vue"
import clock from "../components/svg/clock.vue"

import BorderTitle from "../components/borderTitle.vue";

export default {
    name: "downloadService",
    components: {
        copy,
        downloadSvg: download,
        clock,
        BorderTitle
    },
    data() {
        return {
            downloadCountRemain: 0,
            downloadHistoryList: [],
            pagination: {
                limit: 10,
                page: 1
            },
            totalCount: 0,
            downloadButtonLoading: false
        }
    },
    mounted() {
        if (!this.$cookie.get("userName")) {
            this.$message.error(this.$t("application.loginTips"));
            return setTimeout(() => {
                this.$router.push({ path: "/login", query: { do: "login" } })
            }, 1800);
        }

        this.flushHistory();
        this.flushDownloadCount();
    },
    methods: {
        handlePageChange(page) {
            this.pagination.page = page;
            this.flushHistory();
        },
        downloadCurrentFile(url) {
            const aElement = document.createElement("a");
            aElement.href = url;
            aElement.click();
        },
        flushHistory() {
            this.$post('index/qrng_download/list', {
                ...this.pagination
            }, ".historyListContainer").then(({ data }) => {
                this.downloadHistoryList = data.data;
                this.totalCount = data.total;
            });
        },
        flushDownloadCount() {
            this.$post('index/qrng_download/getnum').then(({ data }) => {
                this.downloadCountRemain = data;
            });
        },
        async downloadRandom() {
            this.downloadButtonLoading = true;
            const downloadRes = await this.$post("index/qrng_download/add");
            this.downloadButtonLoading = false;
            if (downloadRes.code == 0) {
                const aElement = document.createElement("a");
                aElement.href = downloadRes.data.url;
                aElement.click();
            } else {
                this.$message.error(downloadRes.msg);
            }
            this.flushDownloadCount();
            this.flushHistory();
        }
    }
}
</script>

<style scoped lang="scss">
@import "../main.scss";
$page-font-color: #005ca3;

.root {
    @include border-box-padding(20px $global-horizontal-padding);
    width: 100%;
}

.downloadTips {
    @include flex-row-center-between;
    width: 100%;
    font-weight: bold;
    text-align: left;
}

.downloadIcon {
    width: 25px;
}

.inlineButton {
    border: 1px solid $page-font-color;
    box-sizing: border-box;
    padding: 5px 25px;
    display: flex;
    align-items: center;
    width: min-content;
    white-space: nowrap;
    margin: 0 10px;
    user-select: none;
    cursor: pointer;
    border-radius: 3px;
    font-weight: normal;
    color: $page-font-color;
}

.downloadButton {
    @include flex-row-center-center;
    @extend .inlineButton;
    color: white;
    padding: 10px 18px;
    background-color: $page-font-color;
    gap: 10px;
}

.inlineButton>svg {
    height: auto;
    width: 25px;
}

.inlineButton>span {
    margin-left: 5px;
}

.historyListContainer {
    width: 100%;
    border-radius: 5px;
    box-sizing: border-box;
    min-height: 100px;
    margin-top: 20px;
}

.downloadHistoryTitle {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 15px;
    color: $page-font-color;
    gap: 5px;
}

.historyItem {
    @include flex-row-center-between;
    @include border-box-padding(15px 0);
    color: $page-font-color;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid lightgray;
}

.historyItem:last-child {
    border-bottom: none;
}

.paginationContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
}
</style>